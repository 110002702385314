import { Box, CircularProgress, Typography } from "@material-ui/core";
import BluePillButton from "components/buttons/BluePillButton";
import React, { useState } from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import LssmService from "api/services/lssmService";

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const AckowledgmentOfMatter = ({ account_no, matter_acknowledged = 1 }) => {
    const { setToast, unsetModal } = useModal();
    const [loading, setLoading] = useState(false);

    const handleSendAcknowledgment = () => {
        setLoading(true);
        LssmService.acknowledgementOfMatter({ account_no }).then((response) => {
            const { data, message, status } = response;

            switch (status) {
                case "success":
                    setToast(message, "success");
                    setLoading(false);
                    unsetModal();
                    break;
                case "error":
                    setToast(message, "error");
                    setLoading(false);
                    unsetModal();

                    break;
                default:
                    break;
            }
        });
    };

    return (
        <Box display={"flex"} alignItems="center" justifyContent={"center"} flexDirection="column" padding={"2em"}>
            <Box mb={2}>
                <Title>Acknowledgement Of Matter</Title>
            </Box>

            <Box mb={2}>
                <Typography variant="body">
                    Click button to confirm acknowledgement of matter for account: {account_no}
                </Typography>
            </Box>

            <Box mb={2}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <BluePillButton variant="contained" onClick={handleSendAcknowledgment}>
                        Send Acknowledgement
                    </BluePillButton>
                )}
            </Box>
        </Box>
    );
};

export default AckowledgmentOfMatter;
