import { Grid, IconButton, MenuItem, TextField } from "@material-ui/core";
import { Formik } from "formik";
import React, { useState } from "react";
import styled from "styled-components";
import * as Yup from "yup";
import write_icon from "../../../assets/icons/FontAwsome (edit).png";
import load_icon from "../../../assets/icons/Layer -16.png";
import cancel_icon from "../../../assets/icons/Cancel.png";
import { colors } from "../../../constants/styles";
import { useModal } from "../../../providers/hooks/useModal";
import styles from "./styles.module.css";
import LssmService from "api/services/lssmService";

const AttachmentInfo = styled.div`
    padding-left: 23px;
    padding-right: 23px;
    padding: 48px 0px;
    @media (min-width: 1920px) {
        padding-left: 29px;
        padding-right: 29px;
    }
`;

const Text = styled.div`
    color: ${colors.blue};
    font-size: 12px;
    font-weight: 500;
    font-family: "Avenir Next Medium";
    display: flex;
    align-items: center;
    span {
        margin-left: 5px;
        line-height: 20px;
        .left_icon {
            margin-right: -5px;
        }
    }
    @media (min-width: 1920px) {
        font-size: 15px;
    }
`;

const WrapperSection = styled.div`
    padding: 20px 0px;
    ${(props) => props.customStyle};
    @media (min-width: 1920px) {
        padding-left: 27px 0px;
    }
    img {
        width: 20px;
        height: auto;
        //margin-bottom: 10px;
        cursor: pointer;
        @media (min-width: 1920px) {
            width: 25px;
            height: auto;
        }
    }
`;

const validationSchema = Yup.object().shape({
    date: Yup.date().required("Date is a required field"),
    type: Yup.string().required("Type is a required field"),
    case_code: Yup.string().required("Case Code is a required field"),
    obtained_by: Yup.string().required("Obtained By is a required field"),
    attorney: Yup.string().required("Attorney is a required field"),
    court_name: Yup.string().required("Court Name is a required field"),
    reserve_price_vaf: Yup.string().required("Reserve Price VAF is a required field"),
    third_party_sale_date: Yup.date().required("Third Party Sale Date is a required field"),
});

const AttachmentInformation = ({ account_status, account_no }) => {
    const { setToast } = useModal();

    const [editMode, setEditMode] = useState(false);

    const [attachmentInfo, setAttachmentInfo] = React.useState({
        date: "",
        type: "",
        case_code: "",
        obtained_by: "",
        attorney: "",
        court_name: "",
        reserve_price_vaf: "",
        third_party_sale_date: "",
        attachment_docs: null,
    });

    const handleDetailsSubmit = async (values) => {
        setAttachmentInfo(values);
        try {
            LssmService.sendAttachmentInformation({
                ...values,
                account_no,
            });
            setToast("Details successfully sent", "success");
            setEditMode(false);
        } catch (error) {
            setToast(error.message, "error");
        }
    };

    const handleEditDetails = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setEditMode(true);
    };

    React.useEffect(() => {
        LssmService.attachmentInformation({ account_no }).then((data) => {
            setAttachmentInfo({
                date: data?.date,
                type: data?.type,
                case_code: data?.case_code,
                obtained_by: data?.obtained_by,
                attorney: data?.attorney,
                court_name: data?.court_name,
                reserve_price_vaf: data?.reserve_price_vaf,
                third_party_sale_date: data?.third_party_sale_date,
            });
        });
    }, []);

    const showEditButton = () => {
        return (
            <span>
                {!editMode ? (
                    <IconButton onClick={handleEditDetails} type="button">
                        <img size={25} src={write_icon} alt="" />
                    </IconButton>
                ) : (
                    <IconButton type="submit">
                        <img size={25} src={load_icon} alt="" />
                    </IconButton>
                )}
            </span>
        );
    };

    return (
        <AttachmentInfo>
            <Formik
                initialValues={attachmentInfo}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={handleDetailsSubmit}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    setValues,
                    setTouched,
                    resetForm,
                    isSubmitting,
                    // touched,
                    values,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container justifyContent="center" flexDirection="column">
                            <Grid item md={8}>
                                <Grid container direction="row">
                                    <Grid item md={8}>
                                        <Grid container spacing={2} justifyContent="space-between">
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Date</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>{attachmentInfo?.date}</div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            type="date"
                                                            fullWidth
                                                            id="date"
                                                            name="date"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.date}
                                                            error={errors.date ? true : false}
                                                            helperText={errors.date}
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Type</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>{attachmentInfo?.type}</div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="type"
                                                            name="type"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.type}
                                                            error={errors.type ? true : false}
                                                            helperText={errors.type}
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Case Code</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>{attachmentInfo?.case_code}</div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="case_code"
                                                            name="case_code"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.case_code}
                                                            error={errors.case_code ? true : false}
                                                            helperText={errors.case_code}
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Obtained By</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>{attachmentInfo?.obtained_by}</div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="obtained_by"
                                                            name="obtained_by"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.obtained_by}
                                                            error={errors.obtained_by ? true : false}
                                                            helperText={errors.obtained_by}
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Attorney</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>{attachmentInfo?.attorney}</div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="attorney"
                                                            name="attorney"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.attorney}
                                                            error={errors.attorney ? true : false}
                                                            helperText={errors.attorney}
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Court Name</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>{attachmentInfo?.court_name}</div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="court_name"
                                                            name="court_name"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.court_name}
                                                            error={errors.court_name ? true : false}
                                                            helperText={errors.court_name}
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                                            <Grid item>
                                                <WrapperSection>
                                                    <div className={styles.title}>
                                                        {account_status === 1 ? showEditButton() : null}
                                                        {editMode && (
                                                            <IconButton
                                                                type="button"
                                                                onClick={() => setEditMode(false)}
                                                            >
                                                                <img size={25} src={cancel_icon} alt="" />
                                                            </IconButton>
                                                        )}
                                                    </div>
                                                </WrapperSection>
                                            </Grid>
                                            <Grid item>
                                                <WrapperSection
                                                    customStyle={{
                                                        display: "flex",
                                                        flexDirection: "column",
                                                        marginTop: "-50px",
                                                    }}
                                                ></WrapperSection>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={8}>
                                        <Grid container spacing={2} justifyContent="flex-start">
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Reserve Price VAF</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>
                                                            {attachmentInfo?.reserve_price_vaf}
                                                        </div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="reserve_price_vaf"
                                                            name="reserve_price_vaf"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.reserve_price_vaf}
                                                            error={errors.reserve_price_vaf ? true : false}
                                                            helperText={errors.reserve_price_vaf}
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Third Party Sale Date</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>
                                                            {attachmentInfo?.third_party_sale_date}
                                                        </div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            type="date"
                                                            id="third_party_sale_date"
                                                            name="third_party_sale_date"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.third_party_sale_date}
                                                            error={errors.third_party_sale_date ? true : false}
                                                            helperText={errors.third_party_sale_date}
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            {/* <Grid item md={4}>
                        <div
                          className={styles.wrapper_section}
                          style={{
                            marginLeft: "-10px",
                          }}
                        >
                          <div className={styles.title}>Upload Documents</div>

                          {!editMode ? (
                            <div className={styles.text}>
                              {attachmentInfo?.attachment_docs}
                            </div>
                          ) : (
                            <TextField
                              variant="outlined"
                              fullWidth
                              type="file"
                              id="attachment_docs"
                              name="attachment_docs"
                              customStyle={{
                                justifyContent: "flex-start",
                              }}
                              value={values.attachment_docs}
                              error={
                                
                                errors.attachment_docs ? true : false
                              }
                              helperText={
                                
                                errors.attachment_docs
                              }
                              onChange={handleChange}
                              isSearch={false}
                            />
                          )}
                        </div>
                      </Grid> */}
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </AttachmentInfo>
    );
};

export default AttachmentInformation;
