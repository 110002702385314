import { Grid, IconButton, TextField } from "@material-ui/core";
import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import write_icon from "../../../../assets/icons/FontAwsome (edit).png";
import load_icon from "../../../../assets/icons/Layer -16.png";
import cancel_icon from "../../../../assets/icons/Cancel.png";
import { useModal } from "../../../../providers/hooks/useModal";
import styles from "./styles.module.css";
import { Visibility } from "@material-ui/icons";
import { colors } from "constants/styles";
import { useAuth } from "providers/hooks/useAuth";
import { useHistory, useLocation } from "react-router-dom";

const validationSchema = Yup.object().shape({
    password: Yup.string().required("New Password is required").min(8),
    password_confirmation: Yup.string()
        .required("Confirm Password is required")
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .min(8),
});

function AccountDetails({ account_details, editMode, setEditMode, setPasswordUpdate }) {
    const { setModal, setPersistent, setToast } = useModal();
    const { hasPermission } = useAuth();
    const { pathname } = useLocation();
    const [showPassword, setShowPassword] = React.useState("password");
    const [initialValues, setInitialValues] = React.useState({
        legal_case_no: "",
        prefered_language: "",
        account_number: "",
        phone_home: "",
        phone_work: "",
        phone_mobile: "",
        phone_other: "",
        email: "",
        physical_address: "",
        postal_address: "",
        phone_home_field: "",
        phone_work_field: "",
        phone_mobile_field: "",
        phone_other_field: "",
        password: "",
        password_confirmation: "",
    });

    const handleEditDetails = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setEditMode(true);
    };

    const setNewPassword = (values) => {
        setPasswordUpdate({ password: values.password, password_confirmation: values.password_confirmation });
    };

    const handleShowPassword = () => {
        showPassword === "password" ? setShowPassword("text") : setShowPassword("password");
    };

    React.useEffect(() => {
        if (account_details != null) {
            setInitialValues((state) => {
                for (const key in state) {
                    if (key in account_details) {
                        state[key] = account_details[key];
                    }
                }

                return { ...state };
            });
        }
    }, [account_details]);

    return (
        <div className={styles.personal_detail}>
            <Formik
                initialValues={initialValues}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={(values, { resetForm }) => {
                    setNewPassword(values);
                    resetForm({ values: "" });
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    setValues,
                    setTouched,
                    resetForm,
                    isSubmitting,
                    touched,
                    values,
                }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <div className={styles.personal_detail_container}>
                            <div>
                                <Grid
                                    container
                                    style={{
                                        borderBottom: "1px solid #F0F0F0",
                                        position: "relative",
                                        marginBottom: 0,
                                    }}
                                    spacing={1}
                                    justifyContent="space-between"
                                    alignItems={"center"}
                                >
                                    <Grid item sm>
                                        <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                            <div className={styles.title}>Name</div>
                                            <div className={styles.text}>{account_details?.name}</div>
                                        </div>
                                    </Grid>
                                    <Grid item sm>
                                        <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                            <div className={styles.title}>Surname</div>
                                            <div className={styles.text}>{account_details?.surname}</div>
                                        </div>
                                    </Grid>
                                    <Grid item sm>
                                        <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                            <div className={styles.title}>Account No</div>
                                            <div className={styles.text}>{account_details?.account_number}</div>
                                        </div>
                                    </Grid>
                                    <Grid item sm>
                                        <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                            <div className={styles.title}>ID</div>
                                            <div className={styles.text}>{account_details?.identification_number}</div>
                                        </div>
                                    </Grid>
                                    <Grid item sm>
                                        <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                            <div className={styles.title}>Preferred Language</div>

                                            <div className={styles.text}>{account_details?.prefered_language}</div>
                                        </div>
                                    </Grid>
                                </Grid>
                                <Grid
                                    container
                                    spacing={1}
                                    style={{
                                        borderBottom: "1px solid #F0F0F0",
                                        marginBottom: 0,
                                    }}
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Grid item sm>
                                        <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                            <div className={styles.title}>Legal Case No</div>
                                            <div className={styles.text}>{account_details?.legal_case_no}</div>
                                        </div>
                                    </Grid>
                                    <Grid item sm>
                                        <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                            <div className={styles.title}>Phone-Home</div>
                                            <div className={styles.text}>{account_details?.phone_home}</div>
                                        </div>
                                    </Grid>
                                    <Grid item sm>
                                        <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                            <div className={styles.title}>Phone-Work</div>
                                            <div className={styles.text}>{account_details?.phone_work}</div>
                                        </div>
                                    </Grid>
                                    <Grid item sm>
                                        <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                            <div className={styles.title}>Phone-Mobile</div>
                                            <div className={styles.text}>{account_details?.phone_mobile}</div>
                                        </div>
                                    </Grid>
                                    <Grid item sm>
                                        <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                            <div className={styles.title}>Email</div>
                                            <div className={styles.text}>{account_details?.email}</div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                        {pathname === "/dashboard/edit" && (
                            <Grid
                                container
                                spacing={1}
                                style={{
                                    borderBottom: "1px solid #F0F0F0",
                                    marginBottom: 0,
                                }}
                                alignItems="flex-start"
                            >
                                <Grid item sm>
                                    <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                        {!editMode ? <div className={styles.title}>Password</div> : null}

                                        {!editMode ? (
                                            <>
                                                {/* <Visibility
                                                    onClick={handleShowPassword}
                                                    style={{
                                                        cursor: "pointer",
                                                        marginRight: "1em",
                                                        color: `${colors.blue}`,
                                                    }}
                                                />
                                                <TextField
                                                    type={showPassword}
                                                    id="password"
                                                    name="password"
                                                    customStyle={{
                                                        justifyContent: "flex-start",
                                                    }}
                                                    value={values.password}
                                                    isSearch={false}
                                                    disabled
                                                /> */}
                                            </>
                                        ) : (
                                            <Grid container spacing={1} alignItems="flex-start">
                                                <div style={{ marginRight: "8em" }}>
                                                    <div className={styles.title}>New Password</div>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        type="password"
                                                        id="password"
                                                        name="password"
                                                        customStyle={{
                                                            justifyContent: "flex-start",
                                                        }}
                                                        value={values.password}
                                                        error={touched.password && errors.password}
                                                        helperText={touched.password && errors.password}
                                                        onChange={handleChange}
                                                        isSearch={false}
                                                        placeholder="new password"
                                                    />
                                                </div>
                                                <div style={{ marginRight: "8em" }}>
                                                    <div className={styles.title}>Confirm Password</div>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        type="password"
                                                        id="password_confirmation"
                                                        name="password_confirmation"
                                                        customStyle={{
                                                            justifyContent: "flex-start",
                                                        }}
                                                        value={values.password_confirmation}
                                                        error={
                                                            touched.password_confirmation &&
                                                            errors.password_confirmation
                                                        }
                                                        helperText={
                                                            touched.password_confirmation &&
                                                            errors.password_confirmation
                                                        }
                                                        onChange={handleChange}
                                                        isSearch={false}
                                                        placeholder="confirm password"
                                                    />
                                                </div>
                                            </Grid>
                                        )}
                                    </div>
                                </Grid>

                                <div>
                                    {!editMode ? (
                                        <IconButton type="button" onClick={handleEditDetails}>
                                            <img src={write_icon} alt="" />
                                        </IconButton>
                                    ) : (
                                        <>
                                            <IconButton type="submit">
                                                <img src={load_icon} alt="" />
                                            </IconButton>
                                            <IconButton type="button" onClick={() => setEditMode(false)}>
                                                <img src={cancel_icon} alt="" />
                                            </IconButton>
                                        </>
                                    )}
                                </div>
                            </Grid>
                        )}
                    </form>
                )}
            </Formik>
        </div>
    );
}

export default AccountDetails;
