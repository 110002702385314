import { Grid, Paper } from "@material-ui/core";
import DebtorPortalService from "api/services/debtorPortalService";
import { TitleSection } from "common/Layouts";
import BluePillButton from "components/buttons/BluePillButton";
import { colors } from "constants/styles";
import { useModal } from "providers/hooks/useModal";
import React from "react";
import styled from "styled-components";

const SubTitle = styled.p`
    color: ${colors.blue};
    font-family: Avenir Next Medium;
    span {
        font-weight: bold;
        font-family: Avenir Next Bold;
    }
`;

const Input = styled.input`
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 3;
    color: ${colors.orange};
    width: 4em;
    height: 6em;
    padding: 0.8em;
    border-radius: 1em;
    border: 1px solid ${colors.blue};
    margin-right: 1em;
    text-align: center;
`;

const Form = styled.form`
    display: flex;
    width: 100%;
    column-gap: 0.8em;
`;

const OTP = ({ account_details, passwordUpdate }) => {
    const { setModal, unsetModal, setToast } = useModal();
    const [otpValues, setOtpValues] = React.useState({
        val1: "",
        val2: "",
        val3: "",
        val4: "",
        val5: "",
        val6: "",
    });

    const inputFocus = (e) => {
        if (e.key === "Delete" || e.key === "Backspace") {
            const next = e.target.tabIndex - 2;
            if (next > -1) {
                e.target.form.elements[next].focus();
            }
        } else {
            if (e.key !== " ") {
                const next = e.target.tabIndex;
                if (next < 6) {
                    e.target.form.elements[next].focus();
                }
            }
        }
    };

    const handleChange = (e) => {
        setOtpValues({ ...otpValues, [e.target.name]: e.target.value });
    };

    const handleDone = () => {
        const { val1, val2, val3, val4, val5, val6 } = otpValues;
        const otp = `${val1}${val2}${val3}${val4}${val5}${val6}`;
        DebtorPortalService.passwordUpdate({ ...passwordUpdate, otp }).then((response) => {
            if (response.status) {
                switch (response.status) {
                    case "success":
                        setToast(response.message, "success");
                        unsetModal();
                        break;
                    case "error":
                        setToast(response.message, "error");

                    default:
                        break;
                }
            } else {
                setToast(response.errors.otp, "error");
            }
        });
    };

    return (
        <>
            <TitleSection>
                <span style={{ fontWeight: "bold", color: `${colors.blue}` }}>OTP</span>
            </TitleSection>
            <Paper style={{ borderRadius: "1em", padding: "2em" }}>
                <SubTitle>
                    An OTP has been sent to <span>{account_details.phone_mobile}</span> please enter it below
                </SubTitle>
                <Grid container style={{ marginBottom: "2em" }}>
                    <Form>
                        <Grid item>
                            <Input
                                autoComplete="off"
                                tabIndex={1}
                                maxLength={1}
                                type="numeric"
                                value={otpValues.val1}
                                name="val1"
                                id="val1"
                                onChange={(e) => handleChange(e)}
                                onKeyUp={(e) => inputFocus(e)}
                            />
                        </Grid>
                        <Grid item>
                            <Input
                                autoComplete="off"
                                tabIndex={2}
                                maxLength={1}
                                type="numeric"
                                value={otpValues.val2}
                                name="val2"
                                id="val2"
                                onChange={(e) => handleChange(e)}
                                onKeyUp={(e) => inputFocus(e)}
                            />
                        </Grid>
                        <Grid item>
                            <Input
                                autoComplete="off"
                                tabIndex={3}
                                maxLength={1}
                                type="numeric"
                                value={otpValues.val3}
                                name="val3"
                                id="val3"
                                onChange={(e) => handleChange(e)}
                                onKeyUp={(e) => inputFocus(e)}
                            />
                        </Grid>
                        <Grid item>
                            <Input
                                autoComplete="off"
                                tabIndex={4}
                                maxLength={1}
                                type="numeric"
                                value={otpValues.val4}
                                name="val4"
                                id="val4"
                                onChange={(e) => handleChange(e)}
                                onKeyUp={(e) => inputFocus(e)}
                            />
                        </Grid>
                        <Grid item>
                            <Input
                                autoComplete="off"
                                tabIndex={5}
                                maxLength={1}
                                type="numeric"
                                value={otpValues.val5}
                                name="val5"
                                id="val5"
                                onChange={(e) => handleChange(e)}
                                onKeyUp={(e) => inputFocus(e)}
                            />
                        </Grid>
                        <Grid item>
                            <Input
                                autoComplete="off"
                                tabIndex={6}
                                maxLength={1}
                                type="numeric"
                                value={otpValues.val6}
                                name="val6"
                                id="val6"
                                onChange={(e) => handleChange(e)}
                                onKeyUp={(e) => inputFocus(e)}
                            />
                        </Grid>
                    </Form>
                </Grid>
                <BluePillButton
                    style={{
                        color: `${colors.orange}`,
                        fontSize: "1.5rem",
                        width: "10em",
                        fontWeight: "bold",
                    }}
                    onClick={handleDone}
                >
                    Done
                </BluePillButton>
            </Paper>
        </>
    );
};

export default OTP;
