import { Box, CircularProgress, FormControlLabel, FormGroup, Switch } from "@material-ui/core";
import BluePillButton from "components/buttons/BluePillButton";
import TextArea from "components/inputs/TextArea";
import React, { useState } from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import LssmService from "api/services/lssmService";

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const FraudMatter = ({ account_no, unsetModal }) => {
    const [fraudComment, setFraudComment] = useState("");
    const [fraudDocs, setFraudDoc] = useState("N");
    const [checked, setChecked] = useState(false);
    const { setToast } = useModal();
    const [loading, setLoading] = useState(false);

    const handleSendForFraud = () => {
        setLoading(true);

        LssmService.fraudMatter({
            account_no,
            fraud_comment: fraudComment,
            fraud_docs: fraudDocs,
        }).then((response) => {
            const { data, message, status } = response;

            switch (status) {
                case "success":
                    setToast(message, "success");
                    setLoading(false);
                    unsetModal();
                    break;
                case "error":
                    if (data.error?.fraud_comment) {
                        setToast(data.error?.fraud_comment, "error");
                        setLoading(false);
                    } else {
                        setToast(message, "error");
                        setLoading(false);
                        unsetModal();
                    }
                    break;
                default:
                    break;
            }
        });
    };

    const handleChecked = (e) => {
        setChecked(e.target.checked);
        !checked ? setFraudDoc("Y") : setFraudDoc("N");
    };
    return (
        <Box display={"flex"} alignItems="center" justifyContent={"center"} flexDirection="column" padding={"2em"}>
            <Box mb={2}>
                <Title>Report Fraud Matter</Title>
            </Box>
            {/* <Box mb={2} width="clamp(15em, 50%, 40em)">
                <TextInput value={account_no} label="Account No" />
            </Box> */}
            <Box mb={2} width="clamp(15em, 50%, 40em)">
                <TextArea
                    placeholder="Enter comments about account here"
                    label="Comment"
                    value={fraudComment}
                    onChange={(e) => setFraudComment(e.target.value)}
                />
            </Box>

            <Box mb={2}>
                <FormGroup>
                    <FormControlLabel
                        control={<Switch checked={checked} color="primary" onChange={handleChecked} />}
                        label="Supporting documents available?"
                    />
                </FormGroup>
            </Box>

            <Box mb={2}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <BluePillButton variant="contained" onClick={handleSendForFraud}>
                        Report Fraud
                    </BluePillButton>
                )}
            </Box>
        </Box>
    );
};

export default FraudMatter;
