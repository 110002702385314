import { CircularProgress, Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import * as Yup from "yup";
import { AuthService } from "../../api/services";
import close_icon from "../../assets/icons/Group 414.png";
import logo from "../../assets/icons/logo_big.png";
import { Section } from "../../common/Layouts";
import ButtonComponent from "../../components/ButtonComponent";
import CommonModal from "../../components/CommonModal";
import { DASHBOARD } from "../../constants/pathNames";
import { colors } from "../../constants/styles";
import { IconButton } from "@material-ui/core";
import { useAuthState, useAuthDispatch } from "../../providers/authProvider";
import useAPIError from "../../providers/hooks/useAPIError";
import { loginSuccess } from "../../redux/actions/authAction";
import { setToken } from "../../api";
import { useGlobalLoader } from "../../providers/hooks/useGlobalLoader";
import { useModal } from "providers/hooks/useModal";
import ForgotPassword from "components/modal/ForgotPassword";

const LogoSection = styled.div`
    width: 100%;
    max-width: 300px;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    padding: 4% 0px 5% 0px;
    img {
        width: 100%;
    }
`;
const WrapperForm = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const TextFieldComponent = styled.input`
    border: 1px solid ${colors.lightBlue};
    border-radius: 45px;
    outline: none;
    padding: 13px 20px;
    font-size: 1.2vw;
    color: ${colors.lightBlue};
    margin-bottom: 10px;
    box-sizing: border-box;
    width: 100%;
    ${(props) =>
        props.last &&
        css`
            margin-bottom: 30px;
        `}
    ::placeholder {
        opacity: 0.46;
        font-size: 1.2vw;
        @media (min-width: 1920px) {
            //font-size: 25px;
        }
    }
    @media (min-width: 1920px) {
        //height: 74px;
        width: 570px;
        padding: 21px 42px;
    }
`;

const ForgotPasswordComponent = styled.div`
    text-align: center;
    padding-top: 20px;
    cursor: pointer;
    a {
        text-decoration: none;
        color: ${colors.lightBlue};
        font-size: 1.4vw;
    }
`;

const ButtonLogin = styled.button`
    border: none;
    border-radius: 36px;
    color: ${colors.white};
    background-color: ${colors.lightBlue};
    outline: none;
    cursor: pointer;
    font-weight: bold;
    font-size: 1.2vw;
    width: 140px;
    height: 50px;

    @media (min-width: 1920px) {
        width: 177px;
        height: 72px;
        //font-size: 1.2vw;
    }
`;

const ModalSection = styled.div`
    margin-bottom: 10px;
    display: block;
`;

const ModalContent = styled.div`
    padding: 34px 25px;
    box-sizing: border-box;
    span {
        &.title {
            font-size: 30px;
            color: ${colors.blue};
            text-align: left;
            font-family: "Avenir Next Bold";
            @media (min-width: 1920px) {
                font-size: 40px;
            }
        }
    }
`;

export const TitleSection = styled.div`
    font-size: 1.7vw;
    font-weight: bold;
    font-family: "Avenir Next Bold";
    text-align: center;
    span {
        font-weight: normal;
        margin-left: 10px;
        @media (min-width: 1920px) {
            margin-left: 14px;
        }
    }
    @media (min-width: 1920px) {
        font-size: 30px;
    }
    h4 {
    }
`;

const useStyles = makeStyles((theme) => ({
    wrap_close: {
        position: "absolute",
        top: "8px",
        right: "8px",
        cursor: "pointer",
        zIndex: 10,
    },
}));

function Login({ email, password, isLoggedIn, error, loginSuccess }) {
    // const { onUpdate, onLogin } = useAuthDispatch();
    const { toggleLoading } = useGlobalLoader();
    // const { email, password, isLoggedIn, error} = useAuthState();
    const { addError } = useAPIError();
    const [openForgotModal, setOpenForgotModal] = useState(false);
    const [openResetLinkModal, setOpenResetLinkModal] = useState(false);
    const { setToast } = useModal();
    const [loading, setLoading] = useState(false);
    const classes = useStyles();
    const history = useHistory();
    const { setModal } = useModal();

    const LoginSchema = Yup.object().shape({
        email: Yup.string().required("Field is required").email("This string must be email"),
        password: Yup.string().required("Field is required"),
    });

    const initialValues = {
        email: "",
        password: "",
    };

    const handleForgotClose = () => {
        setOpenForgotModal(false);
    };

    const handleForgotOpen = () => {
        setModal({
            Component: <ForgotPassword />,
            size: "sml",
            canClickAway: true,
        });
    };

    const handleResetClose = () => {
        setOpenResetLinkModal(false);
    };

    const handleResetOpen = () => {
        setOpenResetLinkModal(true);
    };

    const handleSubmitForgotPassword = () => {
        AuthService.sendResetPasswordLink(email).then((res) => {
            if (res.status != "success") {
                handleForgotClose();
                addError("Sending email forgot password error");
            } else {
                handleForgotClose();
                handleResetOpen();
            }
        });
    };

    useEffect(() => {
        if (isLoggedIn) {
            history.push(DASHBOARD);
        }
    }, [isLoggedIn]);

    const login = async () => {
        if (sessionStorage.getItem("token")) {
            toggleLoading();
            setToken(sessionStorage.getItem("token"));
            const resp = await AuthService.getUserFromToken();
            if (resp?.status === "success" && resp.data) {
                const obj = {
                    id: resp.data.user.id,
                    email: resp.data.user.email,
                    name: resp.data.user.name,
                    user_type_id: resp.data.user.user_type_id,
                    permissions: resp.data.user.permissions,
                    token: resp.data.api_access_token,
                    worker_id: resp.data.worker_sid,
                    profile_pic_url: resp.data.user.profile_pic_url,
                    first_log_in: resp.data.user.first_log_in,
                };
                loginSuccess(obj);
                history.push("/dashboard");
                toggleLoading();
            } else {
                sessionStorage.removeItem("token");
                toggleLoading();
            }
        }
    };

    useEffect(() => {
        if (!isLoggedIn) {
            login();
        }
    }, []);

    return (
        <div>
            <LogoSection>
                <img src={logo} alt="" />
            </LogoSection>
            <Formik
                initialValues={initialValues}
                validationSchema={LoginSchema}
                onSubmit={async (values) => {
                    setLoading(true);
                    const resp = await AuthService.login({
                        email: values.email,
                        password: values.password,
                    });
                    if (resp?.status === "success" && resp.data) {
                        const obj = {
                            id: resp.data.user.id,
                            email: resp.data.user.email,
                            name: resp.data.user.name,
                            user_type_id: resp.data.user.user_type_id,
                            permissions: resp.data.user.permissions,
                            token: resp.data.api_access_token,
                            worker_id: resp.data.user.worker_sid,
                            profile_pic_url: resp.data.user.profile_pic_url,
                            first_log_in: resp.data.user.first_log_in,
                        };
                        loginSuccess(obj);
                        history.push(DASHBOARD);
                    } else {
                        console.log(resp);
                        setToast(resp.message, "error");
                    }
                    setLoading(false);
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    setValues,
                    setTouched,
                    isSubmitting,
                    touched,
                    values,
                }) => (
                    <Form>
                        <Grid container justify="center">
                            <Grid item md={3}>
                                <WrapperForm>
                                    <TextFieldComponent
                                        name="email"
                                        type="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        error={errors.email}
                                        placeholder="Email"
                                    />
                                    {errors.email && (
                                        <label
                                            style={{
                                                color: "red",
                                                textAlign: "left",
                                            }}
                                        >
                                            {errors.email}
                                        </label>
                                    )}

                                    <TextFieldComponent
                                        name="password"
                                        type="password"
                                        last={errors.password ? false : true}
                                        error={errors.password}
                                        value={values.password}
                                        onChange={handleChange}
                                        placeholder="Password"
                                    />
                                    {errors.password && <label style={{ color: "red" }}>{errors.password}</label>}
                                    <ButtonLogin disabled={loading} type="submit">
                                        {loading ? <CircularProgress size={28} style={{ color: "#fff" }} /> : "Login"}
                                    </ButtonLogin>
                                </WrapperForm>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
            <ForgotPasswordComponent>
                <a onClick={handleForgotOpen}>Forgot password?</a>
            </ForgotPasswordComponent>

            {/* <CommonModal
        open={openForgotModal}
        handleClose={() => setOpenForgotModal(false)}
      >
        <ModalContent>
          <div onClick={handleForgotClose} className={classes.wrap_close}>
            <img width={20} src={close_icon} alt={""} />
          </div>
          <TitleSection>Forgot Password</TitleSection>
          <ModalSection>Please enter your email address below</ModalSection>

          <ModalSection>
            <TextFieldComponent
              name="email"
              type="email"
              value={email || ""}
              onChange={onUpdate}
              placeholder="Email"
            />
          </ModalSection>
          <ModalSection>
            <ButtonComponent
              height={50}
              width={150}
              fontSize={30}
              title="Send Email"
              handler={handleSubmitForgotPassword}
            />
          </ModalSection>
        </ModalContent>
      </CommonModal> */}

            <CommonModal open={openResetLinkModal} handleClose={() => setOpenResetLinkModal(false)}>
                <div onClick={handleResetClose} className={classes.wrap_close}>
                    <IconButton>
                        <img width={16} src={close_icon} alt={""} />
                    </IconButton>
                </div>
                <ModalContent>
                    <TitleSection>Thank You</TitleSection>
                    <Section>
                        We have sent you an email with a password reset link, please follow the instructions in the
                        mail.
                        <ButtonComponent
                            height={30}
                            width={89}
                            fontSize={20}
                            title="Continue"
                            handler={handleResetClose}
                        />
                    </Section>
                </ModalContent>
            </CommonModal>
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        email: state.auth.email,
        password: state.auth.password,
        isLoggedIn: state.auth.error,
    };
};

const mapDispatchToProps = {
    loginSuccess,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
