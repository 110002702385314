import * as api from "..";
import { LSSM } from "api/config/endpoints";

const LssmService = {
    async fraudMatter({ account_no, fraud_comment, fraud_docs }) {
        const resp = await api.post(LSSM.FRAUD_MATTER, ...arguments);
        return resp;
    },
    async adminSupport({ account_no, subject, text }) {
        const resp = await api.post(LSSM.ADMIN_SUPPORT, ...arguments);
        return resp;
    },
    async acknowledgementOfMatter({ account_no }) {
        const resp = await api.post(LSSM.ACKNOWLEDGEMNT_OF_MATTER, ...arguments);
        return resp;
    },
    async recallConfirmation({ account_no }) {
        const resp = await api.post(LSSM.RECALL_CONFIRMATION, ...arguments);
        return resp;
    },
    async returnMatterToSBSA({ account_no, reason_code }) {
        const resp = await api.post(LSSM.RETURN_MATTER_TO_SBSA, ...arguments);
        return resp;
    },
    async recallReasons() {
        const resp = await api.get(LSSM.RECALL_REASONS);
        return resp.data.recall_reasons;
    },
    async attachmentInformation({ account_no }) {
        const resp = await api.get(LSSM.ATTACHMENT_INFORMATION + `?account_no=${account_no}`);
        return resp.data;
    },
    async sendAttachmentInformation({
        date,
        type,
        case_code,
        obtained_by,
        attorney,
        court_name,
        reserve_price_vaf,
        third_party_sale_date,
        account_no,
    }) {
        const resp = await api.post(LSSM.SEND_ATTACHMENT_INFORMATION, ...arguments);
        return resp;
    },

    async sendCustomerInformation({ account_no }) {
        const resp = await api.post(LSSM.SEND_CUSTOMER_INFORMATION, ...arguments);
        return resp;
    },

    async saveCustomerCollectionReport({
        account_no,
        contact_type_id,
        contact_comments,
        report_date,
        visit_type,
        visit_comment,
        how_found,
        where_found,
        sect_129_ltr_sent_date,
    }) {
        const resp = await api.post(LSSM.SAVE_CUSTOMER_COLLECTION_REPORT, ...arguments);
        return resp;
    },

    async getCustomerCollectionReport({ account_no }) {
        const resp = await api.get(LSSM.GET_CUSTOMER_COLLECTION_REPORT + `?account_no=${account_no}`);
        return resp.data.collection_report;
    },
};

export default LssmService;
