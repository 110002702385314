import { Grid, Tab, Typography } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import BluePillButton from "components/buttons/BluePillButton";
import { LocaleSettings } from "constants/locale";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { colors } from "../../constants/styles";
import legal_fees_icon from "../../assets/icons/FontAwsome (balance-scale).png";
import settlement_icon from "../../assets/icons/Layer -10.png";
import capital_icon from "../../assets/icons/Layer -11.png";
import payments_icon from "../../assets/icons/Layer -12.png";
import fees_icon from "../../assets/icons/Layer -13.png";
import interest_icon from "../../assets/icons/Layer -9.png";
import { Box, ButtonBack, TitleSection } from "../../common/Layouts";
import CaseService from "../../services/caseService";
import style from "./Debtor.module.css";
import { useModal } from "providers/hooks/useModal";
import { ContactSupportOutlined } from "@material-ui/icons";
import SendEmail from "./components/SendEmail";
import PaymentCommitment from "./components/PaymentCommitment/PaymentCommitment";
import ViewStatement from "./components/ViewStatement/ViewStatement";
import AccountDetails from "./components/AccountDetails/AccountDetails";
import OTP from "./components/PaymentCommitment/components/OTP";
import { useAuth } from "providers/hooks/useAuth";
import DebtorPortalService from "api/services/debtorPortalService";

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

function DebtorPortal() {
    const history = useHistory();
    const { setModal, unsetModal, setToast } = useModal();
    const authState = useSelector((state) => state.auth);
    const { state: locationState, pathname } = useLocation();
    const [loading, setLoading] = React.useState(true);
    const configList = ["account details"];
    const [activeItem, setActiveItem] = useState(configList[0]);
    const [debtorAccountNo, setdebtorAccountNo] = React.useState();
    const [debtorSummaryList, setDebtorSummaryList] = React.useState({
        capital: { icon: capital_icon, value: null, title: "capital" },
        interest: {
            icon: interest_icon,
            value: null,
            title: "interest",
        },
        fees: { icon: fees_icon, value: 0, title: "fees" },
        legal_fees: {
            icon: legal_fees_icon,
            value: null,
            title: "legal fees",
        },
        payments: {
            icon: payments_icon,
            value: null,
            title: "payments",
        },
        settlement: {
            icon: settlement_icon,
            value: null,
            title: "settlement",
        },
    });
    const [accountDetailsList, setAccountDetailsList] = React.useState({});
    const [editMode, setEditMode] = React.useState(false);
    const [passwordUpdate, setPasswordUpdate] = React.useState({ password: "", password_confirmation: "" });

    const handleDetailsEvents = (reason) => {
        console.log("reason", reason);
        switch (reason) {
            case "save":
                setLoading(true);
                break;
            case "tracing-notification":
                setToast("Tracing results saved in your notes", "success");
                break;
            default:
                break;
        }
    };

    const populateDebtorSummaryList = (response) => {
        let { data } = response;

        let accounts_data = {
            account_number: "",
            company_address: "",
            company_name: "",
            department: "",
            email: "",
            employee_number: "",
            employer_address_line1: "",
            employer_address_line2: "",
            employer_address_line3: "",
            employer_email: "",
            employer_tel_no: "",
            id: "",
            identification_number: "",
            legal_case_no: "",
            name: "",
            occupation: "",
            other_income: "",
            pay_day: "",
            pay_period: "",
            payment_period: "",
            phone_home: "",
            phone_mobile: "",
            phone_work: "",
            phone_other: "",
            physical_address: "",
            postal_address: "",
            prefered_language: "",
            proll: "",
            salary_amount: "",
            sallary_cap: "",
            salary_proof_status: "",
            salary_pay_date: "",
            supervisor: "",
            surname: "",
            ptp_minimum: "",
            settlement: "",
            status: "",
            total_expenditure: "",
            password: "",
            phone_home_field: "",
            phone_mobile_field: "",
            phone_work_field: "",
            phone_other_field: "",
            client_id: 0,
        };

        setAccountDetailsList({ ...accounts_data });

        if (data != null) {
            setDebtorSummaryList((state) => {
                for (const key in state) {
                    if (key in data) {
                        state[key].value = Number(data[key] ?? 0).toLocaleString(LocaleSettings.locale, {
                            minimumFractionDigits: LocaleSettings.minimumFractionDigits,
                            maximumFractionDigits: LocaleSettings.maximumFractionDigits,
                        });
                    }
                }
                return { ...state };
            });

            // storing the raw data
            setAccountDetailsList((state) => {
                for (const key in state) {
                    if (key in data && data[key] != null) {
                        state[key] = data[key];
                    }
                }
                return { ...state };
            });
        }
    };

    const getDebtor = async () => {
        const resp = await DebtorPortalService.debtorCaseRegistry().then((response) => {
            const { data, message, status } = response;
            if (Object.keys(data).length === 0) {
                setToast("No information for this user", "warning");
                return;
            }

            switch (status) {
                case "success":
                    setdebtorAccountNo(data?.debtor_cases[0]?.account_number);
                    break;
                case "error":
                    setToast(message, "error");
                    break;
                default:
                    break;
            }
        });
    };

    const getDebtorSummary = (data) => {
        if (data != null) {
            setLoading(true);
            history.push(data);
        }

        CaseService.debtorCaseSummary({
            account_no: debtorAccountNo,
        })
            .then((response) => populateDebtorSummaryList(response))
            .finally(() => {
                setLoading(false);
            });
    };

    const updatePassword = () => {
        if (passwordUpdate.password.length === 0) {
            setToast("Cannot update empty password", "error");
        } else {
            setModal({
                Component: (
                    <OTP unsetModal={unsetModal} account_details={accountDetailsList} passwordUpdate={passwordUpdate} />
                ),
                size: "sml",
            });
            setEditMode(false);
            DebtorPortalService.passwordOTP();
        }
    };

    React.useEffect(() => {
        getDebtor();
        getDebtorSummary();
    }, [debtorAccountNo]);

    return (
        <div className={style.wrapper_detail}>
            <TitleSection>
                <ButtonBack
                    height={30}
                    width={91}
                    fontSize={15}
                    small_size={12}
                    isCustom
                    customStyle={{
                        textTransform: "uppercase",
                        fontFamily: "Avenir Next Demi Bold",
                    }}
                    onClick={() => history.goBack()}
                >
                    <ArrowBackIcon fontSize="small" /> Back
                </ButtonBack>
                <span style={{ fontWeight: "bold" }}>DEBTOR ACCOUNT DETAILS</span>
            </TitleSection>
            <Box padding={"14px 20px"} paddingSmall={"10px 15px"}>
                <Title>DEBTOR SUMMARY</Title>
                <Grid container spacing={1} wrap="nowrap" alignItems="center" justifyContent="space-between">
                    {Object.values(debtorSummaryList).map((el) => (
                        <Grid key={el.title} md={2} item>
                            <div className={style.innerSummary}>
                                <div className={style.summaryLogo}>
                                    <img
                                        className={el.title === "legal fees" ? style.legalIcon : ""}
                                        src={el.icon}
                                        alt=""
                                    />
                                </div>
                                <div>{el.title}</div>
                                <span>{`R ${el.value}`}</span>
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </Box>

            <div className={style.mainSection}>
                <Tab
                    label={"Account Details"}
                    style={{
                        fontWeight: "bold",
                        fontSize: "1vw",
                        fontFamily: "Avenir Next Bold",
                        backgroundColor: `${colors.blue}`,
                        color: `${colors.white}`,
                        borderRadius: "16px 16px 0 0",
                        marginLeft: ".8em",
                    }}
                />

                <Box>
                    <AccountDetails
                        account_details={accountDetailsList}
                        activeItem={activeItem}
                        dispatch={handleDetailsEvents}
                        linkedAccountView={getDebtorSummary}
                        editMode={editMode}
                        setEditMode={setEditMode}
                        setPasswordUpdate={setPasswordUpdate}
                    />
                </Box>
            </div>

            <div style={{ margin: "1em 0" }}>
                {pathname !== "/dashboard/edit" && (
                    <>
                        <PaymentCommitment
                            account_status={accountDetailsList?.status}
                            account_no={accountDetailsList.account_number}
                            account_settlement={accountDetailsList.settlement}
                            ptp_minimum={accountDetailsList.ptp_minimum}
                        />
                        <BluePillButton
                            onClick={() =>
                                setModal({
                                    Component: <ViewStatement unsetModal={unsetModal} />,
                                    size: "sml",
                                })
                            }
                        >
                            View Statement
                        </BluePillButton>
                    </>
                )}
            </div>

            <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", margin: "2em 0" }}>
                {!editMode && pathname !== "/dashboard/edit" && (
                    <BluePillButton
                        style={{ color: `${colors.orange}` }}
                        onClick={() =>
                            setModal({
                                Component: <SendEmail unsetModal={unsetModal} account={accountDetailsList} />,
                            })
                        }
                    >
                        <ContactSupportOutlined />
                        <Typography style={{ marginLeft: ".6em", fontWeight: "bold", fontSize: "1.2rem" }}>
                            Log A Query
                        </Typography>
                    </BluePillButton>
                )}

                {editMode && (
                    <BluePillButton
                        style={{ color: `${accountDetailsList.phone_mobile ? colors.orange : ""}` }}
                        onClick={() => {
                            updatePassword();
                        }}
                        disabled={accountDetailsList.phone_mobile ? false : true}
                    >
                        <Typography
                            style={{ marginLeft: ".6em", fontWeight: "bold", fontSize: "1.2rem", width: "6em" }}
                        >
                            Next
                        </Typography>
                    </BluePillButton>
                )}
            </div>
        </div>
    );
}

export default DebtorPortal;
