import React, { useState } from "react";
import { Box, Button, ButtonGroup, Card, CardActions, CardContent, Typography } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import BluePillButton from "components/buttons/BluePillButton";

const WelcomeScreen = ({ unsetModal, setToast }) => {
    const authState = useSelector((state) => state.auth);
    const history = useHistory();

    return (
        <Card style={{ width: "30em", padding: "2em" }}>
            <CardContent>
                <Typography>
                    Welcome to <span style={{ color: "midnightblue", fontWeight: "bold" }}>Collect</span>
                    <span style={{ color: "orange", fontWeight: "bold" }}> IQ</span> Debtor Portal{" "}
                    <span style={{ fontWeight: "bold", color: "steelblue" }}>{authState.name}</span>, would you like to
                    reset your password?
                </Typography>
            </CardContent>
            <CardActions
                style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <BluePillButton
                    key="yes"
                    name="yes"
                    onClick={() => {
                        history.push("/dashboard/edit");
                        unsetModal();
                    }}
                >
                    Yes
                </BluePillButton>
                <BluePillButton
                    key="no"
                    name="no"
                    onClick={(e) => {
                        unsetModal();
                        setToast("You've opted to keep your supplied password", "warning");
                    }}
                >
                    No
                </BluePillButton>
            </CardActions>
        </Card>
    );
};

export default WelcomeScreen;
